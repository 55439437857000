var __jsx = React.createElement;
import React, { Fragment, useContext } from 'react';
import Anchor from '../../../atoms/Anchor';
import List from '../../../molecules/List/List';
import ListItem from '../../../molecules/List/ListItem';
import PhoneIcon from '../../../../static/images/icons/Phone';
import SmsIcon from '../../../../static/images/icons/Comment';
import LocationIcon from '../../../../static/images/icons/Location';
import { getAddressURL, getCompleteAddress, convertPhoneFormat } from '../../../../utils/utilityFunction';
import analyticsConstants from '../../../../constants/analyticsConstants';
import { locationLabels } from '../../../../constants/labels/en';
import Clock from '../../../../static/images/icons/Clock';
import Dropdown from '../../../molecules/Dropdown';
import HoursOfOperation from './HoursOfOperation';
import { Context } from '../../../../global-state-context';
var headerAnalytics = analyticsConstants.globalHeader;
var contactInformationData = {
  Address: {
    Icon: LocationIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_ADDRESS,
    title: function title(address) {
      return address;
    },
    url: function url(addressUrl) {
      return addressUrl;
    }
  },
  Email: {
    Icon: null,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_EMAIL,
    title: function title(email) {
      return email;
    },
    url: function url(email) {
      return "mailto:".concat(email);
    }
  },
  Fax: {
    Icon: PhoneIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_FAX,
    title: function title(fax) {
      return convertPhoneFormat(fax);
    },
    url: function url(fax) {
      return "tel:".concat(convertPhoneFormat(fax));
    }
  },
  Phone: {
    Icon: PhoneIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title(phone) {
      return "".concat(convertPhoneFormat(phone));
    },
    url: function url(phone) {
      return "tel:".concat(convertPhoneFormat(phone));
    }
  },
  Text: {
    Icon: SmsIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title(sms) {
      return "".concat(convertPhoneFormat(sms));
    },
    url: function url(sms) {
      return "sms: ".concat(convertPhoneFormat(sms));
    }
  },
  'Hours of Operation': {
    Icon: Clock,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title() {
      return '';
    }
  }
};
var Hours = function Hours(_ref) {
  var className = _ref.className,
    ContactIcon = _ref.ContactIcon,
    label = _ref.label,
    openDropdownOnClick = _ref.openDropdownOnClick,
    yextContactInformationData = _ref.yextContactInformationData;
  var _useContext = useContext(Context),
    yextInfo = _useContext.yextInfo;
  var classes = {
    wrapper: className,
    button: 'nva-header__contact-item u-caption',
    dropdown: 'nva-header__contact-submenu'
  };
  if (yextInfo !== null && yextInfo !== void 0 && yextInfo.openHoursMessage) {
    var hoursLabel = __jsx(React.Fragment, null, label ? __jsx("span", {
      className: "nva-header__contact-label"
    }, label) : '');
    var openMessage = __jsx(React.Fragment, null, hoursLabel, " ", yextInfo.openHoursMessage);
    return __jsx(Dropdown, {
      classes: classes,
      title: yextInfo.openHoursMessage,
      label: openMessage,
      Icon: ContactIcon,
      type: "link",
      openDropdownOnClick: openDropdownOnClick,
      analyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
      analyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_HOURS
    }, __jsx(HoursOfOperation, {
      yextContactInformationData: yextContactInformationData
    }));
  }
};
var ContactAnchor = function ContactAnchor(_ref2) {
  var className = _ref2.className,
    type = _ref2.type,
    label = _ref2.label,
    contactTitle = _ref2.contactTitle,
    contactUrl = _ref2.contactUrl,
    ContactIcon = _ref2.ContactIcon;
  if (!contactTitle || !contactUrl) return;
  var _contactInformationDa = contactInformationData[type],
    dataAnalyticsType = _contactInformationDa.dataAnalyticsType,
    dataAnalyticsValue = _contactInformationDa.dataAnalyticsValue;
  var url = contactInformationData[type].url(contactUrl);
  var title = contactInformationData[type].title(contactTitle);
  return __jsx(ListItem, {
    className: className
  }, __jsx(Anchor, {
    removeDefaultStyles: true,
    title: title,
    ctaBehavior: "Open in a new page",
    className: "nva-header__contact-item u-caption",
    to: url,
    "data-analytics-type": dataAnalyticsType,
    "data-analytics-value": dataAnalyticsValue
  }, ContactIcon && __jsx(ContactIcon, null), label && __jsx("span", {
    className: "nva-header__contact-label"
  }, label), " ", title));
};
var ContactItem = function ContactItem(_ref3) {
  var _contactInformationDa2;
  var type = _ref3.type,
    label = _ref3.label,
    icon = _ref3.icon,
    contactData = _ref3.contactData;
  var _contactData$type = contactData[type],
    className = _contactData$type.className,
    contactTitle = _contactData$type.title,
    contactUrl = _contactData$type.url;
  var Icon = (_contactInformationDa2 = contactInformationData[type]) === null || _contactInformationDa2 === void 0 ? void 0 : _contactInformationDa2.Icon;
  var ContactIcon;
  if (icon !== null && icon !== void 0 && icon.embedCode) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-header__contact-icon",
        title: type,
        dangerouslySetInnerHTML: {
          __html: icon.embedCode
        }
      });
    };
  } else if (icon !== null && icon !== void 0 && icon.image) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-header__contact-icon",
        title: type
      }, __jsx("img", {
        src: icon.image.url,
        alt: ""
      }));
    };
  } else if (Icon) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-header__contact-icon",
        title: type
      }, __jsx(Icon, null));
    };
  }
  if (type === 'Hours of Operation') {
    return __jsx(Hours, {
      className: className,
      ContactIcon: ContactIcon,
      label: label,
      yextContactInformationData: contactData[type].yextContactInformationData,
      openDropdownOnClick: contactData[type].openDropdownOnClick
    });
  }
  return __jsx(ContactAnchor, {
    className: className,
    type: type,
    label: label,
    contactTitle: contactTitle,
    contactUrl: contactUrl,
    ContactIcon: ContactIcon
  });
};
var ContactInformation = function ContactInformation(_ref4) {
  var items = _ref4.items,
    yextContactInformationData = _ref4.yextContactInformationData,
    openDropdownOnClick = _ref4.openDropdownOnClick;
  var contactItems = items.length ? items : [{
    type: 'Address',
    label: locationLabels.addressCamelCase
  }, {
    type: 'Email',
    label: locationLabels.email
  }, {
    type: 'Fax',
    label: locationLabels.fax
  }, {
    type: 'Phone',
    label: locationLabels.phone
  }, {
    type: 'Text',
    label: locationLabels.sms
  }
  // { type: 'Hours of Operation', label: '' }, // uncomment to add as default
  ];

  var GMBUrl = (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_googleMyBusinessCID) && "https://maps.google.com/maps?cid=".concat(yextContactInformationData.c_googleMyBusinessCID);
  var phone = (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_websitePhone) || (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.mainPhone);
  var sms = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_textPhone;
  var address = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.address;
  var fax = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.fax;
  var email = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.emails;
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  var contactData = {
    Address: {
      title: addressString,
      url: GMBUrl || addressStringURL,
      className: 'nva-header__contact-address'
    },
    Email: {
      title: email,
      url: email,
      className: 'nva-header__contact-email'
    },
    Fax: {
      title: fax,
      url: fax,
      className: 'nva-header__contact-fax'
    },
    Phone: {
      title: phone,
      url: phone,
      className: 'nva-header__contact-phone'
    },
    Text: {
      title: sms,
      url: sms,
      className: 'nva-header__contact-sms'
    },
    'Hours of Operation': {
      openDropdownOnClick: openDropdownOnClick,
      yextContactInformationData: yextContactInformationData,
      className: 'nva-header__contact-hours'
    }
  };
  return __jsx("div", {
    className: "nva-header__contact"
  }, __jsx(List, {
    className: "nva-header__contact-list"
  }, contactItems.map(function (_ref5) {
    var type = _ref5.type,
      label = _ref5.label,
      icon = _ref5.icon;
    return __jsx(Fragment, {
      key: type
    }, __jsx(ContactItem, {
      type: type,
      label: label,
      icon: icon,
      contactData: contactData
    }));
  })));
};
export default ContactInformation;
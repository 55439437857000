var __jsx = React.createElement;
import React, { useState, Component, Fragment } from 'react';
import Anchor from '../../../atoms/Anchor';
import List from '../../../molecules/List/List';
import ListItem from '../../../molecules/List/ListItem';
import analyticsConstants from '../../../../constants/analyticsConstants';
var footerAnalytics = analyticsConstants.globalFooter;
var UtilityMenu = function UtilityMenu(_ref) {
  var items = _ref.items;
  return __jsx("nav", {
    className: "nva-footer__utils"
  }, __jsx(List, {
    className: "nva-footer__utils-list"
  }, items === null || items === void 0 ? void 0 : items.map(function (utilityLink) {
    return __jsx(ListItem, {
      key: utilityLink.sys.id
    }, __jsx(Anchor, {
      className: "nva-footer__utils-item u-caption",
      removeDefaultStyles: true,
      title: utilityLink.label,
      "aria-label": utilityLink.ariaLabel,
      to: utilityLink.url,
      hyperlinkType: utilityLink.hyperlinkType,
      ctaBehavior: utilityLink.behavior,
      "data-analytics-type": footerAnalytics.analyticsType.GLOBAL_NAV,
      "data-analytics-value": utilityLink && utilityLink.dataAnalyticsValue ? utilityLink.dataAnalyticsValue : footerAnalytics.analyticsValue.GLOBAL_NAV_UTILITIES
    }, utilityLink.label));
  })));
};
export default UtilityMenu;